import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {ICaseState} from '../../store/case-state.interface';
import {Subscription} from 'rxjs';
import {CasestatusService} from '../../services/casestatus.service';
import {CaseSaveMode, CaseService} from '../../services/case.service';
import {ICase} from '../case.interface';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-auskehrunglist-screen',
  templateUrl: './auskehrunglist-screen.component.html',
  styleUrls: ['./auskehrunglist-screen.component.css']
})
export class AuskehrunglistScreenComponent implements OnInit, OnDestroy {
  caseSub: Subscription;
  startDate: string;
  endDate: string;
  caselist: ICase[] = []
  clSub: Subscription;
  loading = false;
  mode = 'Variante 2';
  sum = 0;
  constructor(private store: Store<{ cases: ICaseState }>,
              public casestatusService: CasestatusService,
              public userService: UserService,
              private caseService: CaseService) {
    this.caseSub = this.store.select('cases').subscribe(
      (casesState) => {
        this.startDate = casesState.startDateForAuszahlungslisteScreen;
        this.endDate = casesState.endDateForAuszahlungslisteScreen;
        this.mode = casesState.modeForAuszahlungslisteScreen;
        this.sum = 0;
        if (this.startDate && this.endDate) {
          this.loading = true;
          if (this.mode === 'Variante 2') {
            this.clSub = this.caseService.loadAuskehrungListScreenCL(this.startDate, this.endDate).subscribe(
            (result) => {
              this.caselist = result;
              for (const c of result) {
                for (const i of c.zahlungen) {
                  for (const z of i.einzelzahlungen) {
                    this.sum += z.sum;
                  }
                }
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
              this.loading = false;
            },
            () => {
            }
          );
          } else {
            this.clSub = this.caseService.loadAuskehrungListScreen(this.startDate, this.endDate).subscribe(
            (result) => {
              this.caselist = result;
              for (const c of result) {
                for (const i of c.zahlungen) {
                  for (const z of i.einzelzahlungen) {
                    this.sum += z.sum;
                  }
                }
              }
              this.loading = false;
            },
            (error) => {
              console.log(error);
              this.loading = false;
            },
            () => {
            }
          );
          }
        }
      }
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.caseSub.unsubscribe();
    this.clSub.unsubscribe();
  }

  back() {
    this.caseService.goToCaseSearch();
  }
}
