import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subscription, timer} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ConstantsService} from './constants.service';
import {AuthenticationService} from './authentication.service';
import {Store} from '@ngrx/store';
import * as CaseActions from '../store/case.actions';
import {
  IAppNotification,
  NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_ERROR,
  NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_LOADING,
  NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_TIMEOUT,
  NOTIFICATION_MESSAGE_CASEDETAIL_SAVE_ERROR,
  NOTIFICATION_MESSAGE_CASEDETAIL_SAVE_TIMEOUT,
  NOTIFICATION_MESSAGE_CASEDETAIL_SAVING,
  NOTIFICATION_MESSAGE_CASELIST_CREATE_NEW_CASE_ERROR,
  NOTIFICATION_MESSAGE_CASELIST_CREATE_NEW_CASE_IN_PROGRESS,
  NOTIFICATION_MESSAGE_CASELIST_CREATE_NEW_CASE_TIMEOUT,
  NOTIFICATION_MESSAGE_CASELIST_LOADER_ERROR,
  NOTIFICATION_MESSAGE_CASELIST_LOADER_LOADING,
  NOTIFICATION_MESSAGE_CASELIST_LOADER_TIMEOUT,
  NOTIFICATION_MESSAGE_PERSONDETAIL_LOADER_ERROR,
  NOTIFICATION_MESSAGE_PERSONDETAIL_LOADER_LOADING, NOTIFICATION_MESSAGE_PERSONDETAIL_LOADER_TIMEOUT,
  NOTIFICATION_SLOT_CASEDETAIL_LOADER,
  NOTIFICATION_SLOT_CASELIST_LOADER,
  NOTIFICATION_SLOT_PERSONDETAIL_LOADER
} from '../app-notifications/app-notification.interface';
import * as AppNotificationActions from '../store/app-notification.actions';
import {ICaseState} from '../store/case-state.interface';
import * as UiActions from '../store/ui.actions';
import {MODULES, MODULE_PAGES} from '../routing/routes.model';
import {RoutingService} from '../routing/routing.service';
import {INetworkState} from '../store/network-state.interface';
import {IAuthUser} from '../models/auth-user.interface';
import * as NetworkActions from '../store/network.actions';
import {DebugLogService} from './debug-log.service';
import {ICase, ICaseAblagegrund, ICaselistScreen} from '../cases/case.interface';
import {IStammbaumPerson} from '../stammbaum/stammbaum-person.interface';
import {RightsManagementService} from './rights-management.service';
import * as NoteDraftActions from '../store/note-draft.actions'

const TRY_RELOAD_INTERVAL = 10 * 1000; // 10 Sekunden

export enum CaseSaveMode {
  Kein_Modus_gewaehlt = 0,
  Rahmendaten = 1,
  Erblasserdaten = 2,
  Nachlassdaten = 3,
  Unterfall_zu_Fall = 4,
  Bundesanzeigertext = 5,
  Fallzusammenfassung = 6,
  Urkundensuche = 7,
  EBS_Zusammenfassung = 8,
  EBS_Daten = 9,
  Status = 10,
  Fallliste_Daten = 11,
}

export enum CaseDisplayOpenable {
  Bundesanzeigertext = 1,
  Fallzusammenfassung = 2,
  Urkundensuche = 3,
  EBS_Zusammenfassung = 4
}

@Injectable()
export class CaseService implements OnDestroy {
  // the below lines are new for CaseDetailView 2.0
  private activeCaseData: ICase = null;
  public activeCaseDataSubject = new BehaviorSubject<ICase>(<ICase>{});

  casesSubscription: Subscription;
  loadAllCases = false;
  cases: ICase[];
  activeCaseId: number = null;
  activeCaseLastName: string = null;
  network: INetworkState;
  networkSubscription: Subscription;
  authUser: IAuthUser;
  authUserSubscription: Subscription;
  reloadTimerSubscription: Subscription;
  noTimeoutFlag = false;
  caselistLoadingInProgress = false;
  createNewCaseInProgress = false;
  saveCaseInProgress = false;
  erklaerungOptions = [{id: 0, description: '-------------'}, {id: 1, description: 'Nachlassgericht'}, {id: 2, description: 'Notar'}]
  ablagegrundOptions = [];

  private baseUrl: string;

  constructor(private authenticationService: AuthenticationService,
              private debugLogService: DebugLogService,
              private constantsService: ConstantsService,
              private routingService: RoutingService,
              private http: HttpClient,
              private store: Store<{cases: ICaseState, network: INetworkState, authUser: {authUser: IAuthUser}}>
              ) {
    this.baseUrl = constantsService.getApiEndpoint();
    this.casesSubscription = this.store.select('cases').subscribe((cases) => {
      this.loadAllCases = cases.loadAllCases;
      this.cases = cases.cases;
      this.activeCaseId = cases.activeCaseId;
      if (cases.activeCase) {
        this.activeCaseLastName = cases.activeCase.last_name;
        // the below lines are new for CaseDetailView 2.0
        this.activeCaseData = cases.activeCase;
        this.activeCaseDataSubject.next(this.activeCaseData);
      }
    });
    this.networkSubscription = this.store.select('network').subscribe((network) => {
      this.network = network
    });
    this.authUserSubscription = this.store.select('authUser').subscribe((authUser) => {
      this.authUser = authUser.authUser
    });
    this.reloadTimerSubscription = timer(0, TRY_RELOAD_INTERVAL).subscribe(t => {
      if (!this.ablagegrundOptions || this.ablagegrundOptions.length === 0 && this.authUser.loggedIn && this.network.isConnectedToServer) {
        this.http.get<ICaseAblagegrund[]>(`${this.baseUrl}/ablagegrund/`, {headers: this.constantsService.getHttpOptions()}).subscribe(
          (result) => { this.ablagegrundOptions = result; },
          (error) => { console.log(error); },
          () => {}
        );
      }
      if (this.network.updateNeededCase && this.authUser.loggedIn && this.network.isConnectedToServer) { // if user is logged in, reload is neccessary and there is a connection
        this.getCasesFromServer();
      }
    });
  }

  getAblagegrundOptions() {
    return this.ablagegrundOptions;
  }

  getAblagegrundOptionText(grund: number) {
    const g = this.ablagegrundOptions.find(x => x.id === grund);
    if (!g) { return ''; }
    return g.description;
  }

  triggerCaselistReloadFromServer() {
    this.store.dispatch(new NetworkActions.SetUpdateNeededCaseTrue());
    this.getCasesFromServer();
  }

  getNameForCaseId(case_id: number) {
    const result: ICase = this.cases.find(x => (+x.id === +case_id) && (x.id > 0));
    if (result && result.last_name) {
      return result.last_name.toUpperCase();
    } else {
      return '';
    }
  }

  getCasesFromServer() {
    if (this.caselistLoadingInProgress) {
      this.debugLogService.log('load cases called, but already running, so ignore');
      return;
    }
    this.caselistLoadingInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASELIST_LOADER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASELIST_LOADER_LOADING));
    this.noTimeoutFlag = false;
    this.debugLogService.log('load cases execute');
    let query_string = `${this.baseUrl}/v3/case-shortlist/`;
    if (!this.loadAllCases) {
      this.debugLogService.log('limit case loading to cases without Status Ablage');
      query_string += '?limit=true'
    }
    this.http.get<ICase[]>(query_string, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (response) => {
        this.store.dispatch(new CaseActions.ReplaceCasesInCaselist(response));
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASELIST_LOADER));
        this.store.dispatch(new NetworkActions.SetUpdateNeededCaseFalse());
        this.noTimeoutFlag = true;
        this.caselistLoadingInProgress = false;
        if (this.loadAllCases) {
          this.store.dispatch(new CaseActions.SetLoadAllCasesExecutedFlag(true));
          this.store.dispatch(new NetworkActions.AddMessage({
            event_timestamp: null,
            text: 'Die Fall-Liste wurde geladen (vollständig)',
            level: 0
          }));
        } else {
          this.store.dispatch(new NetworkActions.AddMessage({
            event_timestamp: null,
            text: 'Die Fall-Liste wurde geladen (ohne Fälle Status Ablage)',
            level: 0
          }));
        }
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASELIST_LOADER));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASELIST_LOADER_ERROR));
        this.noTimeoutFlag = true;
        this.caselistLoadingInProgress = false;
        this.store.dispatch(new NetworkActions.AddMessage({
          event_timestamp: null,
          text: 'Fehler beim Laden der Fall-Liste',
          level: 2
        }));
      },
      () => {
        if (!this.noTimeoutFlag) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASELIST_LOADER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASELIST_LOADER_TIMEOUT));
        }
        this.caselistLoadingInProgress = false;
      }
    );
  }

  reloadCaseDetailsForActiveCaseFromServer() {
    if (this.activeCaseId) {
      this.getCaseDetailsFromServer(this.activeCaseId);
    }
  }

  getCaseDetailsFromServer(id: number) {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASEDETAIL_LOADER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_LOADING));
    this.noTimeoutFlag = false;
    this.http.get<ICase>(`${this.baseUrl}/v2/case-details/${id}/`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (response) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASEDETAIL_LOADER));
        this.store.dispatch(new CaseActions.SetActiveCase({id: id, caseData: response}));
        this.noTimeoutFlag = true;
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASEDETAIL_LOADER));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_ERROR));
        this.noTimeoutFlag = true;
      },
      () => {
        if (!this.noTimeoutFlag) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASEDETAIL_LOADER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASEDETAIL_LOADER_TIMEOUT));
        }
      }
    );
  }

  getPersonDetailsFromServer(id: number) {
    return this.http.get<IStammbaumPerson>(`${this.baseUrl}/v3/stammbaum-person/${id}/`, {headers: this.constantsService.getHttpOptions()});
  }

  loadCaselistScreen(status_id: number) {
    const body = {status_id: status_id};
    return this.http.post<ICaselistScreen[]>(`${this.baseUrl}/caselist-screen/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  loadAuskehrungListScreen(start_date: string, end_date: string) {
    const body = { start_date: start_date, end_date: end_date };
    return this.http.post<ICase[]>(`${this.baseUrl}/auskehrung-list-screen/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  loadAuskehrungListScreenCL(start_date: string, end_date: string) {
    const body = { start_date: start_date, end_date: end_date };
    return this.http.post<ICase[]>(`${this.baseUrl}/auskehrung-list-screen-cl/`, body, {headers: this.constantsService.getHttpOptions()});
  }

  getErklaerungOptions() {
    return this.erklaerungOptions;
  }

  getTitleTextForActiveCase() {
    if (!this.activeCaseData) { return ''; }
    let caseNumberDisplay = '';
    if (this.activeCaseData.old_id !== '') {
      caseNumberDisplay = this.activeCaseData.old_id;
    } else {
      caseNumberDisplay = this.activeCaseData.id.toString();
    }
    if (!this.activeCaseData.last_name) { return ''; }
    let caseNameDisplay = this.activeCaseData.last_name;
    if (this.activeCaseData.first_name && (this.activeCaseData.last_name + ' ' + this.activeCaseData.first_name).length < 26) {
      caseNameDisplay = this.activeCaseData.last_name + ' ' + this.activeCaseData.first_name;
    }
    return 'Fall  ' + caseNumberDisplay + ': ' + caseNameDisplay;
  }

  getCasesNeu() {
    return this.http.get<ICase[]>(`${this.baseUrl}/v5/get-cases-neu/`, {headers: this.constantsService.getHttpOptions()});
  }

  getCasesNichtZugeordnet() {
    return this.http.get<ICase[]>(`${this.baseUrl}/v5/get-cases-nicht-zugeordnet/`, {headers: this.constantsService.getHttpOptions()});
  }

  getCasesRechercheAsTeamMember() {
    return this.http.get<ICase[]>(`${this.baseUrl}/v5/get-cases-recherche-as-team-member/`, {headers: this.constantsService.getHttpOptions()});
  }
  getCasesRechercheAsTeamLeader() {
    return this.http.get<ICase[]>(`${this.baseUrl}/v5/get-cases-recherche-as-team-leader/`, {headers: this.constantsService.getHttpOptions()});
  }

  getCasesPool() {
    return this.http.get<ICase[]>(`${this.baseUrl}/v5/get-cases-pool/`, {headers: this.constantsService.getHttpOptions()});
  }

  getCasesOfJunior(user_id: number) {
    return this.http.get<ICase[]>(`${this.baseUrl}/v5/get-cases-for-junior/?user=${user_id}`, {headers: this.constantsService.getHttpOptions()});
  }

  getCasesBoerse() {
    return this.http.get<ICase[]>(`${this.baseUrl}/v5/get-cases-boerse/`, {headers: this.constantsService.getHttpOptions()});
  }

  searchCases(searchstring: string, limitToStatus = null, order = 0, limitResultsToUser = 0, birthplace = null, court = null, source = null, realestate = 0) {
    return this.http.get<ICase[]>(`${this.baseUrl}/v3/case-search/?q=${searchstring}&limit=${limitToStatus}&order=${order}&users=${limitResultsToUser}&court=${court}&birthplace=${birthplace}&source=${source}&realestate=${realestate}`, {headers: this.constantsService.getHttpOptions()});
  }

  searchPersons(searchstring: string) {
    return this.http.get<IStammbaumPerson[]>(`${this.baseUrl}/v3/person-search/?q=${searchstring}`, {headers: this.constantsService.getHttpOptions()});
  }

  goToCasedetailId(id: number, text = '', ignoreForHistoryList = false, activeTab = 0) {
    if (!id) {
      return;
    }
    if (activeTab === 0) { this.store.dispatch(new CaseActions.SetActiveCaseDetailTab(1)); } else {
      this.store.dispatch(new CaseActions.SetActiveCaseDetailTab(activeTab));
    }

    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.CASEDETAILMAX}));
    this.store.dispatch(new CaseActions.SetActiveCase({id: id, caseData: <ICase>{}}));
    this.getCaseDetailsFromServer(id);
    this.routingService.navigateToModule({moduleName: MODULES.CASES});
    if (!ignoreForHistoryList) {
      this.store.dispatch(new CaseActions.AddCaseIdToHistory({id: id, text: text}));
    }
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.CASEDETAILMAX}));
    this.store.dispatch(new NoteDraftActions.DeleteNoteDraft());
  }

  goToPersondetailId(id: number, text = '', ignoreForHistoryList = false) {
    if (!id) {
      return;
    }
    this.store.dispatch(new CaseActions.SetActivePerson({id: id, personData: <IStammbaumPerson>{}}));
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_PERSONDETAIL_LOADER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_PERSONDETAIL_LOADER_LOADING));
    this.noTimeoutFlag = false;
    this.getPersonDetailsFromServer(id).subscribe(
      (response) => {
        console.log(response);
        console.log(this.activeCaseId);
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_PERSONDETAIL_LOADER));
        this.store.dispatch(new CaseActions.SetActivePerson({id: id, personData: response}));
        this.noTimeoutFlag = true;
        if (response.case !== this.activeCaseId) { // if a person from a different case was loaded, load the case also!
          this.getCaseDetailsFromServer(response.case_id);
        }
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_PERSONDETAIL_LOADER));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_PERSONDETAIL_LOADER_ERROR));
        this.noTimeoutFlag = true;
      },
      () => {
        if (!this.noTimeoutFlag) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_PERSONDETAIL_LOADER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_PERSONDETAIL_LOADER_TIMEOUT));
        }
      }
    );

    this.routingService.navigateToModule({moduleName: MODULES.CASES});
    if (!ignoreForHistoryList) {
      this.store.dispatch(new CaseActions.AddPersonIdToHistory({id: id, text: text}));
    }
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.PERSONDETAIL}));
  }

  goToBoerse() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.BOERSE}));
    this.store.dispatch(new CaseActions.SetCaseModuleHeadline('Fall-Börse'));
  }

  goToCaseSearch() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.CASESEARCH}));
  }

  goToCaseListScreen(status: number) {
    this.store.dispatch(new CaseActions.SetStatusForCaselistScreen(status));
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.CASELISTSCREEN}));
  }

  goToAuskehrungListScreen(startDate, endDate, mode) {
    this.store.dispatch(new CaseActions.SetDatesForAuszahlunglistScreen({startDate: startDate, endDate: endDate, modeForAuszahlungslisteScreen: mode}));
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.AUSKEHRUNGLISTSCREEN}));
  }

  getExcelAuskehrungList(startDate, endDate) {
    // todo implement
  }

  goToPool() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.POOL}));
    this.store.dispatch(new CaseActions.SetCaseModuleHeadline('Fall-Pool'));
  }

  goToNewCaseView() {
    this.store.dispatch(new UiActions.SetActivePage({module: MODULES.CASES, page: MODULE_PAGES.CASES.CASEDETAILMAX}));
  }

  clearMessages() {
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASELIST_LOADER));
  }

  createNewCase() {
    if (this.createNewCaseInProgress) { // if creating new case is already in progress, then ignore
      return;
    }
    this.createNewCaseInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASELIST_LOADER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASELIST_CREATE_NEW_CASE_IN_PROGRESS));
    this.http.get<ICase>(`${this.constantsService.getApiEndpoint()}/v2/case_create/`, {headers: this.constantsService.getHttpOptions()}).subscribe(
      (response) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASELIST_LOADER));
        this.store.dispatch(new CaseActions.AddCaseToCaselist(response));
        this.createNewCaseInProgress = false;
        this.goToCasedetailId(response.id);
      },
      (error) => {
        this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASELIST_LOADER));
        this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASELIST_CREATE_NEW_CASE_ERROR));
        this.createNewCaseInProgress = false;
      },
      () => {
        if (this.createNewCaseInProgress) {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASELIST_LOADER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASELIST_CREATE_NEW_CASE_TIMEOUT));
          this.createNewCaseInProgress = false;
        }
      }
    );
  }

  saveCaseData(caseData: ICase, saveMode = null) {
    let payload = {};
    if (saveMode === CaseSaveMode.Rahmendaten) {
      payload = {
        old_id: caseData.old_id,
        court: caseData.court,
        institution: caseData.institution,
        court_reference: caseData.court_reference,
        date_of_record_access: caseData.date_of_record_access,
        date_asked_for_record_access: caseData.date_asked_for_record_access,
        weitere_akteneinsichten: caseData.weitere_akteneinsichten,
        judical_officer: caseData.judical_officer,
        fiscal: caseData.fiscal,
        fiscal_date: caseData.fiscal_date,
        fiscal_end_date: caseData.fiscal_end_date,
        fiskus_aufgehoben: caseData.fiskus_aufgehoben,
        source: caseData.source,
        user_made_record_access: caseData.user_made_record_access,
        user_asked_for_record_access: caseData.user_asked_for_record_access,
        bundesanzeiger_date: caseData.bundesanzeiger_date,
        midterm_report_court_date: caseData.midterm_report_court_date,
        midterm_report_curator_date: caseData.midterm_report_curator_date,
        additional_information: caseData.additional_information
      };
    }
    if (saveMode === CaseSaveMode.Status) {
      payload = {
        status: caseData.status,
        ablagegrund: caseData.ablagegrund,
        ablagegrund_erlaeuterung: caseData.ablagegrund_erlaeuterung
      };
    }
    if (saveMode === CaseSaveMode.Fallliste_Daten) {
      payload = {
        bemerkung_fuer_fallliste: caseData.bemerkung_fuer_fallliste,
        auszahlungssumme_fuer_fallliste: caseData.auszahlungssumme_fuer_fallliste
      };
    }
    if (saveMode === CaseSaveMode.Erblasserdaten) {
      payload = {
        last_name: caseData.last_name,
        first_name: caseData.first_name,
        birth_name: caseData.birth_name,
        divorced_name: caseData.divorced_name,
        birth_date: caseData.birth_date,
        birth_place: caseData.birth_place,
        birth_country: caseData.birth_country,
        deceased_date: caseData.deceased_date,
        deceased_place: caseData.deceased_place,
        deceased_country: caseData.deceased_country,
        last_address_full: caseData.last_address_full,
        testament_vorhanden: caseData.testament_vorhanden,
        testament_infos: caseData.testament_infos
      };
    }
    if (saveMode === CaseSaveMode.Nachlassdaten) {
      payload = {
        curator: caseData.curator,
        curator_reference: caseData.curator_reference,
        value: caseData.value,
        total_value: caseData.total_value,
        real_estate: caseData.real_estate,
        real_estate_description: caseData.real_estate_description
      };
    }
    if (saveMode === CaseSaveMode.Unterfall_zu_Fall) {
      payload = {
        parent_case: caseData.parent_case
      };
    }
    if (saveMode === CaseSaveMode.Bundesanzeigertext) {
      payload = {
        bundesanzeiger_text: caseData.bundesanzeiger_text
      };
    }
    if (saveMode === CaseSaveMode.Fallzusammenfassung) {
      payload = {
        summary_text: caseData.summary_text
      };
    }
    if (saveMode === CaseSaveMode.Urkundensuche) {
      payload = {
        urkundensuche_text: caseData.urkundensuche_text
      };
    }
    if (saveMode === CaseSaveMode.EBS_Zusammenfassung) {
      payload = {
        ebs_zusammenfassung: caseData.ebs_zusammenfassung
      };
    }
    if (saveMode === CaseSaveMode.EBS_Daten) {
      payload = {
        value: caseData.value,
        esa_created_date: caseData.esa_created_date,
        esa_created_user: caseData.esa_created_user,
        teilerbschein: caseData.teilerbschein,
        gesamterbschein: caseData.gesamterbschein,
        erben_im_ausland: caseData.erben_im_ausland,
        konkurrenzfirmen: caseData.konkurrenzfirmen,
        uebersetzung_noetig: caseData.uebersetzung_noetig,
        uebersetzung_sprachen: caseData.uebersetzung_sprachen,
        uebersetzung_erledigt_date: caseData.uebersetzung_erledigt_date,
        uebersetzung_erledigt_user: caseData.uebersetzung_erledigt_user,
        erbe_fuer_ev: caseData.erbe_fuer_ev,
        sicherungsaufforderung_noetig: caseData.sicherungsaufforderung_noetig,
        as_zu_esa_angelegt_date: caseData.as_zu_esa_angelegt_date,
        as_zu_esa_angelegt_user: caseData.as_zu_esa_angelegt_user,
        ra_noetig: caseData.ra_noetig,
        ra_name: caseData.ra_name,
        notar_fuer_ev: caseData.notar_fuer_ev,
        verbleib_geld: caseData.verbleib_geld,
        esa_eidesstattliche_erklaerung: caseData.esa_eidesstattliche_erklaerung,
        esa_eingereicht_date: caseData.esa_eingereicht_date,
        esa_eingereicht_user: caseData.esa_eingereicht_user,
        ev_veranlasst_date: caseData.ev_veranlasst_date,
        ev_veranlasst_user: caseData.ev_veranlasst_user,
        ev_erfolgt_date: caseData.ev_erfolgt_date,
        ebs_erlassen_date: caseData.ebs_erlassen_date,
        ebs_eingetroffen_date: caseData.ebs_eingetroffen_date,
        ebs_eingetroffen_user: caseData.ebs_eingetroffen_user
      };
    }

    this.saveCaseInProgress = true;
    this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASEDETAIL_LOADER));
    this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASEDETAIL_SAVING));
    this.http.patch(`${this.baseUrl}/v3/case-detail/${caseData.id}/`, payload, {headers: this.constantsService.getHttpOptions()})
      .subscribe(
        (response) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASEDETAIL_LOADER));
          this.saveCaseInProgress = false;
        },
        (error) => {
          this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASEDETAIL_LOADER));
          this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASEDETAIL_SAVE_ERROR));
          this.saveCaseInProgress = false;
        },
        () => {
          if (this.saveCaseInProgress) {
            this.store.dispatch(new AppNotificationActions.ClearAppNotificationSlot(NOTIFICATION_SLOT_CASEDETAIL_LOADER));
            this.store.dispatch(new AppNotificationActions.AddAppNotification(NOTIFICATION_MESSAGE_CASEDETAIL_SAVE_TIMEOUT));
            this.saveCaseInProgress = false;
          }
        }
      );
  }

  // this is old and can be removed after refactoring is completed todo
  save(singleCase: ICase, save_mode: string = null) {


  }

  getActiveCaseId() {
    return this.activeCaseId;
  }

  getActiveCaseLastName() {
    return this.activeCaseLastName;
  }

  // DEPRECATED
  statusHTML(singleCase: ICase) {
    let str: string = singleCase.status;
    if (singleCase.status == 'neu') {
      str = '<label class="label label-danger">' + singleCase.status + '</label>';
    }
    if (singleCase.status == 'offen') {
      str = '<label class="label label-success">' + singleCase.status + '</label>';
    }
    return str;
  }

  getExpandedHeirs(case_id) {
    return this.http.post<IStammbaumPerson[]>(`${this.constantsService.getApiEndpoint()}/v5/expanded-heirs-for-case/`, {id: case_id}, {headers: this.constantsService.getHttpOptions()});
  }

  getExcelCaseList(statusId: number) {
    const token = localStorage.getItem('token');
    const submitForm = document.createElement('form');
    submitForm.style.display = 'none';
    const target = 'Fall-Liste';
    submitForm.target = target;
    submitForm.method = 'POST';
    submitForm.action = this.constantsService.getApiEndpoint() + '/caselist-xlsx/';
    const formInput = document.createElement('input');
    formInput.type = 'text';
    formInput.name = 'api_token';
    formInput.value = token;
    const numberInput = document.createElement('input');
    numberInput.type = 'text';
    numberInput.name = 'status_id';
    numberInput.value = statusId.toString();
    submitForm.appendChild(formInput);
    submitForm.appendChild(numberInput);
    document.body.appendChild(submitForm);
    const wind = window.open('', target);
    if (wind) {
      submitForm.submit();
    }
  }

  ngOnDestroy() {
    this.casesSubscription.unsubscribe();
    this.networkSubscription.unsubscribe();
    this.authUserSubscription.unsubscribe();
    this.reloadTimerSubscription.unsubscribe();
  }
}

