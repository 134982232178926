import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../services/user.service';
import {DocumentsService} from '../../documents/documents.service';

@Component({
  selector: 'app-fax-einsicht-modal',
  templateUrl: './fax-einsicht-modal.component.html',
  styleUrls: ['./fax-einsicht-modal.component.css']
})
export class FaxEinsichtModalComponent implements OnInit {
  einsichtType = 1;
  einsichtPerson: number;
  einsichtGericht: number;
  einsichtDatum: string;
  userOptions = [];
  caseId: number;

  constructor(private ngbActiveModal: NgbActiveModal,
              private userService: UserService,
              private documentsService: DocumentsService) { }

  ngOnInit() {
    this.userOptions = this.usersAsOptions();
  }

  cancel() {
    this.ngbActiveModal.dismiss();
  }

  send() {
    this.documentsService.createFaxAntragAkteneinsicht(this.caseId, this.einsichtType, this.einsichtPerson, this.einsichtGericht, this.einsichtDatum);
    this.ngbActiveModal.close(true);
  }

  usersAsOptions() {
    return this.userService.usersAsOptions();
  }
}
